import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { LangService } from './shared/lang.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router } from '@angular/router';
import { AuthServiceInventario } from './services/auth-service.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = environment.isMultiColorActive;
  constructor(private router:Router, private langService: LangService, private renderer: Renderer2,private bnIdle: BnNgIdleService,private serviceAuth:AuthServiceInventario) {

     this.bnIdle.startWatching(3600).subscribe((res) => {
      if(res) {
          this.onSignOut();
      }
    });

  }

  ngOnInit(): void {
    this.langService.init();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }

  onSignOut(): void {
    this.serviceAuth.logOut().subscribe(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('tup');
      this.router.navigate(['/user/login']);
    });
  }

  
}
