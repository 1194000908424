import { environment } from 'src/environments/environment';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  level?: number;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
   {
    icon: 'iconsminds-home',
    label: 'Home',
    to: `${adminRoot}/homePage`,
    level: 0
  },
  {
    icon: 'iconsminds-notepad',
    label: 'Productos',
    to: `${adminRoot}/dashboards`,
    level:0,
    subs: [
      {
        icon: 'simple-icon-list',
        label: 'Lista de Productos',
        to: `${adminRoot}/listaProductos`,
        level:0
      },
      {
        icon: 'simple-icon-plus',
        label: 'Registrar Producto',
        to: `${adminRoot}/AgregarProducto`,
        level:0
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'Venta Producto',
        to: `${adminRoot}/ventaProducto`,
        level:0
      },
      
    ],
  },
   {
    icon: 'iconsminds-male-female',
    label: 'Pacientes',
    to: `${adminRoot}/Pacientes`,
    level:0
  },
   {
    icon: 'iconsminds-monitor-analytics',
    label: 'Reportes',
    to: `${adminRoot}/applications`,
    subs: [
      {
        icon: 'simple-icon-check',
        label: 'Reporte Dia',
        to: `${adminRoot}/ReporteDia`,
        level:0
      },
      {
        icon: 'iconsminds-clock-forward',
        label: 'Reporte Semana',
        to: `${adminRoot}/ReporteSemana`,
        level:0
      },
      {
        icon: 'simple-icon-calculator',
        label: 'Reporte Meses',
        to: `${adminRoot}/ReporteMes`,
        level:0
      },
      {
        icon: 'simple-icon-calendar',
        label:'Reporte Años',
        to: `${adminRoot}/ReporteA`,
        level:0
      },
      {
        icon: 'iconsminds-coins',
        label:'Deudas',
        to: `${adminRoot}/ReporteDeudas`,
        level:0
      },
      {
        icon: 'iconsminds-basket-coins',
        label:'Movimiento Productos',
        to: `${adminRoot}/ProductSales`,
        level:0
      },
    ],
  },
   {
    icon: 'iconsminds-bell',
    label: 'Notificaciones',
    to: `${adminRoot}/Notificaciones`,
    level:0
  }

 
];
export default data;
